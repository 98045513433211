<template>
<div class="page-corte-total">
  <v-container>
    <v-breadcrumbs :items="breadcrumbs">
      <v-icon slot="divider">forward</v-icon>
    </v-breadcrumbs>
  </v-container>

  <v-container fluid fill-height>
    <v-row :justify="'center'" :align="'center'">
      <v-col sm='12'>
        <base-material-card color="primary" icon="library_books" title="Corte General Total" class="elevation-1 px-5 py-3">

      <v-card-text>
        <v-form ref="form" lazy-validation>
          <v-container grid-list-md>
              <v-row>
              <v-col sm="4" md="4" lg="4">
                <v-autocomplete v-model="model.id_sucursal" :items="sucursales" :hide-no-data="true"
                    :hide-selected="true"
                    item-text="nombre" item-value="_id" label="Sucursal"></v-autocomplete>
              </v-col>
              <v-col sm="4" md="4" lg="4">
                  <v-menu offset-x ref="menu1" v-model="menu1"                     
                    :close-on-content-click="false" transition="scale-transition" min-width="290px" offset-y>
                    <template v-slot:activator="{ on }">
                        <v-text-field v-on="on" v-model="model.fecha_de" label="Fecha" color="secondary" prepend-icon="event"
                        @blur="model.fecha_de = parse_date(model.fecha_de)" :rules="[rules.required, valida_fecha]">
                        </v-text-field>
                    </template>

                    <v-date-picker v-model="model.fecha_de" color="secondary" 
                        @change="consultar()"
                        scrollable locale="es-mx" @input="menu1 = false">
                        <v-spacer />

                        <v-btn color="secondary" x-small @click="menu1 = false">
                        Cancelar
                        </v-btn>
                    </v-date-picker>
                    </v-menu>
              </v-col>   
              <v-col sm="2" md="2" lg="2">
                <v-btn color="info" @click.native="consultar()">
                  <v-icon>done</v-icon> {{ "Consultar" }}
                </v-btn>
              </v-col>          
            </v-row>
            

          </v-container>
        </v-form>
      </v-card-text>    
      <v-container>
        
        <v-row>
          <v-col sm="3" md="3" lg="3">
          </v-col>
          <v-col sm="2" md="2" lg="2">
          </v-col>
          <v-col sm="1" md="1" lg="1">
          </v-col>
          <v-col sm="2" md="2" lg="2">
            <strong>Fecha: {{ parse_date_mx(fecha_seleccionada) }}</strong>
          </v-col>
          <v-col sm="3" md="3" lg="3">
          </v-col>
        </v-row>
        <br>
        <v-row>
          <v-col sm="1" md="1" lg="1">
          </v-col>
          <v-col sm="4" md="4" lg="4" style="text-align:right;">
            Total de Ventas:
          </v-col>
          <v-col sm="2" md="2" lg="2" style="text-align:right;">
            ${{ formatNumber(parseFloat(total_ventas).toFixed(2)) }}
          </v-col>
          <v-col sm="2" md="2" lg="2" style="text-align:center;">
            Comision por pagar
          </v-col>
          <v-col sm="2" md="2" lg="2">
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="1" md="1" lg="1">
          </v-col>
          <v-col sm="4" md="4" lg="4" style="text-align:right;">
            Tarjeta de Credito:
          </v-col>
          <v-col sm="2" md="2" lg="2" style="text-align:right;">
            ${{ formatNumber(parseFloat(total_credito).toFixed(2)) }}
          </v-col>
          <v-col sm="2" md="2" lg="2" style="text-align:center;">
            ${{ formatNumber(parseFloat(comision_credito).toFixed(2)) }}
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="1" md="1" lg="1">
          </v-col>
          <v-col sm="4" md="4" lg="4" style="text-align:right;">
            Tarjeta de Debito:
          </v-col>
          <v-col sm="2" md="2" lg="2" style="text-align:right;">
            ${{ formatNumber(parseFloat(total_debito).toFixed(2)) }}
          </v-col>
          <v-col sm="2" md="2" lg="2" style="text-align:center;">
            ${{ formatNumber(parseFloat(comision_debito).toFixed(2)) }}
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="1" md="1" lg="1">
          </v-col>
          <v-col sm="4" md="4" lg="4" style="text-align:right;">
            Transferencias:
          </v-col>
          <v-col sm="2" md="2" lg="2" style="text-align:right;">
            ${{ formatNumber(parseFloat(total_transferencias).toFixed(2)) }}
          </v-col>
          <v-col sm="3" md="3" lg="3">            
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="1" md="1" lg="1">
          </v-col>
          <v-col sm="4" md="4" lg="4" style="text-align:right;">
            Cheques:
          </v-col>
          <v-col sm="2" md="2" lg="2" style="text-align:right;">
            ${{ formatNumber(parseFloat(total_cheques).toFixed(2)) }}
          </v-col>
          <v-col sm="3" md="3" lg="3">            
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="1" md="1" lg="1">
          </v-col>
          <v-col sm="4" md="4" lg="4" style="text-align:right;">
            <strong>Cantidad a Depositar en Efectivo:</strong>
          </v-col>
          <v-col sm="2" md="2" lg="2" style="text-align:right;">
            <strong>${{ formatNumber(parseFloat(total_efectivo).toFixed(2)) }}</strong>
          </v-col>          
        </v-row>
        <br><br>
        <v-row>
          <v-col sm="11" md="11" lg="11" style="text-align:center;">
              <strong>RESUMEN</strong>
          </v-col>          
        </v-row>
        <v-row>
          <v-col sm="1" md="1" lg="1">
          </v-col>
          <v-col sm="4" md="4" lg="4" style="text-align:right;">
            VENTAS CON TARJETA
          </v-col>
          <v-col sm="2" md="2" lg="2" style="text-align:right;">
            ${{ formatNumber(parseFloat(total_tarjetas).toFixed(2)) }}
          </v-col>
          <v-col sm="3" md="3" lg="3">            
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="1" md="1" lg="1">
          </v-col>
          <v-col sm="4" md="4" lg="4" style="text-align:right;">
            VENTAS EN EFECTIVO
          </v-col>
          <v-col sm="2" md="2" lg="2" style="text-align:right;">
            ${{ formatNumber(parseFloat(total_efectivo).toFixed(2)) }}
          </v-col>
          <v-col sm="3" md="3" lg="3">            
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="1" md="1" lg="1">
          </v-col>
          <v-col sm="4" md="4" lg="4" style="text-align:right;">
            VENTAS EN TRANSFERENCIAS
          </v-col>
          <v-col sm="2" md="2" lg="2" style="text-align:right;">
            ${{ formatNumber(parseFloat(total_transferencias).toFixed(2)) }}
          </v-col>
          <v-col sm="3" md="3" lg="3">            
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="1" md="1" lg="1">
          </v-col>
          <v-col sm="4" md="4" lg="4" style="text-align:right;">
            VENTAS EN CHEQUES
          </v-col>
          <v-col sm="2" md="2" lg="2" style="text-align:right;">
            ${{ formatNumber(parseFloat(total_cheques).toFixed(2)) }}
          </v-col>
          <v-col sm="3" md="3" lg="3">            
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="1" md="1" lg="1">
          </v-col>
          <v-col sm="4" md="4" lg="4" style="text-align:right;">
            <strong>TOTAL DE VENTAS</strong>
          </v-col>
          <v-col sm="2" md="2" lg="2" style="text-align:right;">
            <strong>${{ formatNumber(parseFloat(total_ventas).toFixed(2)) }}</strong>
          </v-col>
          <v-col sm="3" md="3" lg="3">            
          </v-col>
        </v-row>
        <br>
        <v-row>
          <v-col sm="11" md="11" lg="11" style="text-align:center;">
                <v-btn v-if="fecha_seleccionada!=''" x-small color="success" @click.native="generar_excel()">
                  <v-icon></v-icon> {{ "Generar Excel" }}
                </v-btn>
              </v-col>          
        </v-row>
      </v-container> 
      <v-card-text>
      </v-card-text>  


        </base-material-card>
      </v-col>
    </v-row>
  </v-container>

</div>
</template>

<script>

export default {
  components: {
  },
  watch: {
    model_filters: {
      handler(val) {
        this.$nextTick(() => {
          this.items = [];
          this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
        });
      },
      deep: true
    },


  },
  mounted: function() {
    if (!this.verificaPermiso('t.ventas.utilidad')) {
      this.$router.replace("AccessDenied").catch(() => {});
    }
  },
  created: function() {
    this.model = this.clean_model();
    var today = window.moment().format("YYYY-MM-DD");        
    this.model.fecha_de = today;
    this.get_formas_pago();
    this.get_sucursales();
  },
  data() {
    return {
        total_ventas:0,
        total_credito:0,
        comision_credito:0,
        total_debito:0,
        comision_debito:0,
        total_transferencias:0,
        total_cheques:0,        
        total_tarjetas:0,
        total_efectivo:0,

        fecha_seleccionada: "",
        columns:[],
        registros:[],
        sucursales:[],
        menu1:false,
        menu2:false,
        menu3:false,
        menu4:false,

      search_marca: null,
      tipos_precios: [],
      categorias:[],
      marcas:[],
      articulos:[],
      articulos_proveedores:[],
      fab: [],
      isLoading: false,
      articulos_encontrados: [],
      id_articulo: "",

      search: "",
      model_filters: "",

      tmp_fecha: "",
      breadcrumbs: [{
          text: "Inicio",
          disabled: false,
          to: "/index"
        },
        {
          text: "Cortes",
          disabled: true,
          href: ""
        },
        {
          text: "Corte Total",
          disabled: true,
          href: ""
        }
      ],

      modal: false,
      modal_articulos: false,
      update: false,
      model: {},
      rules: {
        required: v => !!v || "Este campo es requerido",
        decimal: value => {
          const pattern = /^[\d]*(\.{0,1}[\d]*)$/;
          return pattern.test(value) || "Número decimal no válido";
        },
        entero: function(numero) {
          const pattern = /^[\d]*$/;
          return pattern.test(numero) || "Debe ser un número entero";
        },
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Correo electrónico no valido";
        },
        rfc: v =>
          (v && v.length >= 9 && v.length < 15) || "RFC debe tener al menos 9 caractéres y 14 caractéres"
      },
      items: [],
      PORCENTAJE_CREDITO:0,
      PORCENTAJE_DEBITO:0,
    }
  },
  methods: {
    get_formas_pago: function () {
        let data = {
            "selector": {
                "type":"sat_formas_pago",
                "estatus": "Activo"
            },            
        };

        window.axios
            .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
            .then(response => {                
                if (response.data.docs.length > 0){
                    for(var k in response.data.docs){
                      var item = response.data.docs[k];
                      if(item.clave.toString() == "04" && item.comision){
                        this.PORCENTAJE_CREDITO = parseFloat(item.comision);
                      }
                      else if(item.clave.toString() == "28" && item.comision){
                        this.PORCENTAJE_DEBITO = parseFloat(item.comision);
                      }
                    }                                        
                }                    
            })

            .catch(error => {
                this.$swal({
                    type: "error",
                    title: "¡Operación no Permitida!",
                    text: "Ocurrió un error al obtener las comisiones de crédito y débito.",
                    footer: ""
                });
            });
    },
    get_sucursales: function () {
        let data = {
            "selector": {
                "type":"sucursales",
                "estatus": {
                    "$eq": "Activo"
                }
            },
            "fields": [
                "nombre", "_id"
            ]
        };

        window.axios
            .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
            .then(response => {
                this.sucursales = [];
                if (response.data.docs.length > 0)
                    this.sucursales = response.data.docs;
                    let todas = {
                        _id: '0',
                        nombre: 'TODAS'
                    };
                    this.sucursales.unshift(todas);
            })

            .catch(error => {
                this.$swal({
                    type: "error",
                    title: "¡Operación no Permitida!",
                    text: "Ocurrió un error al obtener las sucursales.",
                    footer: ""
                });
            });
    },
    parse_date(date) {
      if (!date) return null;
      return moment(String(date)).format("YYYY-MM-DD");
    },
    parse_date_mx(date) {
      if (!date) return null;
      return moment(String(date)).format("DD/MM/YYYY");
    },
    valida_fecha: function(fecha) {
      if (fecha == "Invalid date")
        return "Fecha no válida."
      else
        return true;
    },
    valida_time: function(time) {
      if(time!=null && time.toString()!=""){
            if(time.toString().length != 5)
                return "Hora no valida1";
            if(!time.match(/^\d{2}:(?:[0-5]\d)$/))
                return "Hora no valida2";
            var time_arr = time.split(":");
            if(time_arr.length!=2){
                return "Hora no valida3";
            }else{
                if(isNaN(time_arr[0]) || isNaN(time_arr[1])){
                    return "Hora no valida4";
                }
                if(parseInt(time_arr[0])<24 && parseInt(time_arr[1])<60)
                {
                    return true;
                } else{
                    return "Hora no valida5";
                }
            }
      }
      return true;

    },
    clean_model: function() {
      return {
        id_sucursal:"0",
        fecha_de: ''        
      };
    },
    formatNumber: function (x) {
      if (x == undefined) return 0;
      x = x.toString();
      var pattern = /(-?\d+)(\d{3})/;
      while (pattern.test(x)) x = x.replace(pattern, "$1,$2");
      return x;
    },
    consultar: function(){
        if(this.$refs.form.validate()){
        try {
                window.dialogLoader.show('Espere un momento por favor..');
                this.registros = [];
                this.columns = [];
                this.total_ventas = 0;
                this.total_credito = 0;
                this.comision_credito = 0;
                this.total_debito = 0;
                this.comision_debito = 0;
                this.total_transferencias = 0;
                this.total_cheques = 0;
                this.total_tarjetas = 0;
                this.total_efectivo = 0;
                
                var data = {
                  "selector":{
                    "type":"ventas",
                    "usuario_id":{"$exists": true},
                    "sucursal._id":{"$exists": true},
                    //"estatus":{"$in": ["Finalizada", "Facturada", "Factura Global"]},
                    "fecha": {
                        "$gte": this.model.fecha_de,
                        "$lte": this.model.fecha_de+ "T23:59:59.999"
                    }
                  }, //"sort": ["fecha"],
                    "use_index":"_design/93c72e287dccc1c43609554b59c0d7b503195d03"
                }

                if(this.model.id_sucursal!="" && this.model.id_sucursal!="0"){
                  data["selector"]["sucursal._id"] = this.model.id_sucursal;
                }

                window.axios
                    .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
                    .then(response => {                        
                        if (response.data.docs.length > 0){
                          for (var key in response.data.docs) {
                              var doc = response.data.docs[key];
                              for(var kp in doc.pago){                                  
                                  var clave = doc.pago[kp].clave;
                                  if(clave.toString() == "01"){//Efectivo
                                      this.total_efectivo += parseFloat(doc.pago[kp].cantidad);
                                  } else if(clave.toString() == "02"){//chques
                                      this.total_cheques += parseFloat(doc.pago[kp].cantidad);
                                  } else if(clave.toString() == "03"){//transferencias
                                      this.total_transferencias += parseFloat(doc.pago[kp].cantidad);
                                  } else if(clave.toString() == "04"){//Credito
                                      this.total_credito += parseFloat(doc.pago[kp].cantidad);                                      
                                  } else if(clave.toString() == "28"){//debito
                                      this.total_debito += parseFloat(doc.pago[kp].cantidad);                                      
                                  } else if(clave.toString() == "08"){//Vales despensa, indica Alvaro se considere de debito
                                      this.total_debito += parseFloat(doc.pago[kp].cantidad);
                                  } else {
                                    console.log("No considerada: ", doc.pago[kp])
                                  }
                              }

                              this.total_ventas += parseFloat(doc["total"]);
                              this.total_efectivo -= parseFloat(doc["cambio"]);
                          }
                          this.total_tarjetas += this.total_credito;
                          this.total_tarjetas += this.total_debito;

                          if(this.total_credito > 0){
                            this.comision_credito = this.total_credito * (this.PORCENTAJE_CREDITO/100);
                          }
                          if(this.total_debito > 0){
                            this.comision_debito = this.total_debito * (this.PORCENTAJE_DEBITO/100);
                          }
                          this.fecha_seleccionada = this.model.fecha_de;
                        }
                        
                    })
                    .catch(error => {
                      console.log("Error: ", error);
                      if (error.response && error.response.data.error){                        
                        this.$swal({
                            type: "error",
                            title: "¡Sin Información!",
                            text: error.response.data.error,
                            footer: ""
                        });
                      } else {
                        this.$swal({
                            type: "error",
                            title: "¡Operación no Permitida!",
                            text: "Ocurrió un error al realizar la consulta. Intente nuevamente.",
                            footer: ""
                        });
                      }
                      
                    }).then(()=>{
                      window.dialogLoader.hide();
                    });
            } catch (error) {
              console.log("ERRORS: ",error);
            }
        }
    },
    generar_excel: function(){
        if(this.$refs.form.validate()){          
            window.dialogLoader.show('Espere un momento por favor..');

            window.axios
                .post(process.env.VUE_APP_REPORTES_URL + "/reportes/rpt_corte_total/", this.model, {                  
                    "Content-type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Allow": "POST",
                    "cache-control": "no-cache",
                    "responseType": "arraybuffer"
                })
                .then(response => {
                    var bytes = response.data;
                    let blob = new Blob([bytes], {
                            type: response.headers["content-type"]
                        }),
                        url = window.URL.createObjectURL(blob);

                    var a = document.createElement("a");
                    document.body.appendChild(a);
                    a.style = "display: none";
                    a.href = url;
                    a.download = "CorteTotal_"+this.parse_date_mx(this.fecha_seleccionada)+".xlsx";
                    a.click();
                    a.parentNode.removeChild(a);
                })
                .catch(error => {
                  console.log(error);
                  this.$swal({
                      type: "error",
                      title: "¡Operación no Permitida!",
                      text: "Ocurrió un error al generar el reporte. Intente nuevamente.",
                      footer: ""
                  });
                }).then(()=>{
                  window.dialogLoader.hide();
                });            
        }
    },


  }
}
</script>
